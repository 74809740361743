.row-disabled {
  background-color: lightyellow !important;
}

.row-emailonly {
  background-color: lightgreen !important;
}

.row-phoneonly {
  background-color: lightblue !important;
}

.row-locked {
  background-color: lightpink !important;
}
