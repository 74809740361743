.row-disabled {
  background-color: lightgray !important;
}

.row-emailonly {
  background-color: lightgreen !important;
}

.row-phoneonly {
  background-color: lightyellow !important;
}

.row-locked {
  background-color: pink !important;
}

.row-selected {
  background-color: lightblue !important;
}