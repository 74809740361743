img[src$="#custom_marker"] {
  border: 4px solid #fff !important;
  border-radius: 50%;
}

img[src$="#custom_marker_green"] {
  border: 4px solid #008000 !important;
  border-radius: 50%;
}

img[src$="#custom_marker_red"] {
  border: 4px solid #cc3300 !important;
  border-radius: 50%;
}
